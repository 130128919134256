.App {
  text-align: center;
  min-height: 100%;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.page--loading {
  padding-top: 40px;
  height: 300px;
  font-size: 14pt;
  font-weight: 600;
}

.nav-top-container {
  display: grid;
}

.header-tabs {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #62a744;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14pt;
  text-transform: uppercase;
}

.header-tabs ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.header-tabs li {
  display: inline;
  padding: 15px;
}

.nav-top {
  background-color: #62a744;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-links {
  display: flex;
  z-index: 10;
}

.tab-links > h4 {
  padding: 15px;
  font-size: 10pt;
  font-family: azo-sans-web;
}

.nav--smalltext {
  color: white;
  font-size: 9pt;
  font-style: italic;
  text-align: right;
  margin-top: -27px;
  padding-right: 15px;
  font-family: azo-sans-web;
  font-weight: 600;
}

@media (max-width: 760px) {
  .hide-text {
    display: none;
  }
}

.tab--notselected > a:link,
.tab--notselected > a:visited,
.tab--notselected > a:active,
.tab--notselected > a:hover {
  color: white;
  text-decoration: none;
}

.tab--selected {
  background-color: white;
}

.tab--selected > a:link,
.tab--selected > a:visited,
.tab--selected > a:active,
.tab--selected > a:hover {
  color: black;
  background-color: white;
  text-decoration: none;
}

.logo {
  width: 260px;
  height: 53px;
}

.div-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  height: 70px;
}

.social-links {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-right: 40px;
  font-size: 11pt;
  font-weight: 600;
  font-family: azo-sans-web;
}

.social-links a {
  text-decoration: none !important;
  color: black !important;
}

.social-links a:hover {
  color: black !important;
}

.social-spacing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
}

.icon-font {
  font-size: 14pt;
  margin-left: -40px;
}

.nav-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  align-items: center;
  margin-right: 50px;
  padding-top: 10px;
}

.nav-filter-select {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
}

.select-wrapper {
  border-radius: 7px;
  display: inline-block;
  overflow: hidden;
  background: #cccccc;
  border: 2px solid #62a744;
  margin-left: 5px;
  margin-right: 5px;
}

select {
  padding-left: 10px;
  font-weight: 600;
  font-size: 10pt;
  color: #62a744;
  width: 170px;
  height: 25px;
  outline: none;
  border: 0px;
  border-bottom-left-radius: 7px;
}

select option {
  font-weight: 500;
  color: dimgray;
}

.main-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 20px;
  margin: auto;
  max-width: 1400px;
}

/* project cards content */
.projcard {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 275px;
  border-radius: 9px;
  background-color: #edefeb;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.projcard--info {
  margin-top: -15px;
}

.projcard--desc {
  text-align: left;
  padding-left: 10px;
}

.projcard--desc h4 {
  height: 45px;
}

.projcard--desc a:link,
a:visited,
a:active,
a:hover {
  text-decoration: none;
  color: black;
}

.projcard--oneline {
  margin-top: -15px;
  font-size: 10pt;
  padding-right: 10px;
  min-height: 75px;
}

.projcard--stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #dfe1de;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  padding: 10px;
}

.projcard--respects {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50px;
  height: 20px;
  background-color: #62a744;
  color: #fff;
  font-weight: bold;
  font-size: 10pt;
  border-radius: 5px;
  padding: 5px;
  padding-top: 3px;
}

.projcard--auth {
  font-size: 9pt;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
}

.no--projects {
  font-weight: 600;
  width: 100%;
  height: 250px;
}

.authors--link:hover {
  text-decoration: underline !important;
}

.projcard--image {
  width: 100%;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.projcard--image:hover {
  opacity: 0.5;
}

.projcard--image--container {
  cursor: pointer;
  position: relative;
  height: 206px;
  width: 275px;
}

.projcard--img--description {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(3, 104, 55, 0.82);
  color: #fff;
  visibility: hidden;
  opacity: 0;
  padding: 15px;
  margin-top: 0px;
  margin-bottom: 0px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;

  /* transition effect. not necessary */
  transition: opacity 0.2s, visibility 0.2s;
}

.projcard--image--container:hover .projcard--img--description {
  visibility: visible;
  opacity: 1;
}

.projcard--view {
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #7f7b7b;
  font-size: 9pt;
  font-weight: bold;
}

.projcard--view--img {
  max-width: 30px;
  margin-right: 3px;
}

.img--respects {
  max-height: 20px;
}

/* begin pagination styles */
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.paginationItem {
  background: #fff;
  border: 2px solid #666;
  padding: 10px 15px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: relative;
  margin: 0 5px;
  cursor: pointer;
}

.paginationItem span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.prev,
.next {
  background: #fff;
  border: none;
  padding: 10px;
  color: blue;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin: 0 10px;
  cursor: pointer;
}

.paginationItem.active {
  border: 1px solid #888;
  color: #888;
  pointer-events: none;
}

.prev.disabled,
.next.disabled {
  pointer-events: none;
  box-shadow: none;
  color: #999;
}
/* end pagination styles *?

/* Footer styles */

footer {
  background-color: #edefeb;
  color: #7f7b7b;
  margin-top: auto;
  text-align: center;
  width: 100%;
}

.ft-container {
  height: auto;
  margin: 0 auto;
  max-width: 1200px;
}

footer a {
  text-decoration: none !important;
  color: #7f7b7b !important;
}

footer a:hover {
  color: black !important;
}

/* Footer main */
.ft-main {
  padding: 1.25rem 1.875rem 0rem;
  display: flex;
  flex-flow: row wrap;
}

@media only screen and (min-width: 77.5rem /* 1240px */) {
  .ft-main {
    justify-content: space-between;
  }
}

/* center items in footer on mobile devies */
@media only screen and (max-width: 500px) {
  .ft-main{
    justify-content: center !important;
  }
  .ft-main-item {
    text-align: center !important;
  }
  .ft-main-item > ul {
    text-align: center !important;
  }
  .ft-title {
    text-align: center !important;
  }
  .footer-contact {
    text-align: center !important;
  }
  .ft-container {
    text-align: center !important;
  }
  .ft-social {
    justify-content: center !important;
    align-items: center !important;
  }
}

.ft-main-item {
  display: inline;
  min-width: 12.5rem;
  max-width: 300px; 
  padding-left: 10px;  
}

.ft-main-item > ul {
  list-style: none;
  padding-left: 0;
  text-align: left;
  font-size: 11pt;
  font-weight: 600;
}

.ft-main-item > ul > li {
  line-height: 1.5;
}

.ft-title {
  color: #62a744;
  font-family: soleil;
  margin-bottom: 0px;
  padding-bottom: 0px;
  text-align: left;
  cursor: default;
}

.footer-contact {
  text-align: left;
}

.ft-social > ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
}

.container {
  flex: 1;
}


/* Footer social */
.ft-social {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.875rem 1.25rem;
}
.ft-social-list {
  display: flex;
  justify-content: right;
  padding-top: 1.25rem;
}
.ft-social-list li {
  margin: 0.5rem;
  font-size: 1.25rem;
}
/* Footer legal */
.ft-copyright {
  padding: 0.9375rem 1.875rem;
}

.footer-4 {
  padding-bottom: 10px;
  text-align: left;
}

#footer-email {
  margin-bottom: 10px;
  line-height: 20pt;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 0px #fff inset;
  border-radius: 4px;
  box-sizing: border-box;
  padding-left: 10px;
  width: 300px;
  max-width: 50vw;
}

.spinner {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 100;
}

.btn-submit {
  color: white;
  background-color: #62a744;
  height: 34px;
  border-radius: 5px;
  border: 1px solid #7f7b7b;
  width: 300px;
  max-width: 50vw;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
